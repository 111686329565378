import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuetify from 'vuetify/lib';

Vue.use(VueRouter);

Vue.use(Vuetify);

import es from 'vuetify/lib/locale/es'
import fr from 'vuetify/lib/locale/fr'

export default new Vuetify({
    lang: {
        locales: { es, fr },
        current: 'en',
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                rfnavy: '#012754',
                rforange: '#F47434',
                rfaccent: '#049BEB',
                rfaccent2: '#04649C',
                highlight: '#F5F5F5',
                almostblack: '#282828'
            },
            dark: {
                rfnavy: '#012754',
                rforange: '#F47434',
                rfaccent: '#049BEB',
                rfaccent2: '#04649C',
                highlight: '#282828',
                almostblack: '#282828'
            }
        }
    }
});
