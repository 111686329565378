import { render, staticRenderFns } from "./UserAvatar.vue?vue&type=template&id=0d4f7620&scoped=true"
import script from "./UserAvatar.vue?vue&type=script&lang=js"
export * from "./UserAvatar.vue?vue&type=script&lang=js"
import style0 from "./UserAvatar.vue?vue&type=style&index=0&id=0d4f7620&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d4f7620",
  null
  
)

export default component.exports