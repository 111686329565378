<template>
    <v-container>

        <AdBTWR v-if="!user.isAdFree" />

        <h1 class="page-header font-weight-light">Favorite Repeaters</h1>

        <div v-if="user.authenticated">

            <v-alert border="left" color="info" icon="mdi-information" dense text>
                Keep track of your favorite repeaters here. You can also add repeaters to your favorites by clicking the star icon on the repeater page.
            </v-alert>

            <v-card>
                <v-card-text>
                    <v-data-table :headers="headers" :items="favorites" :loading="loading" :options.sync="options"
                        :footer-props="{ itemsPerPageOptions: [10, 20, 30, 40, 50] }" :server-items-length="total"
                        :mobile-breakpoint="0" class="elevation-0" hide-default-footer>
                        <template v-slot:item.Actions="{ item }">
                            <v-rating v-model="item.Starred" clearable dense length="1" color="yellow darken-1"
                                background-color="almostblack lighten-5" title="Favorite Repeater"
                                @input="setFavorite(item.ID, item.Starred)">
                            </v-rating>
                        </template>

                        <template v-slot:item.Name="{ item }">
                            <v-rating v-model="item.Starred" clearable dense length="1" color="yellow darken-1"
                                background-color="almostblack lighten-5" title="Favorite Repeater"
                                @input="setFavorite(item.ID, item.Starred)" class="d-inline mr-2">
                            </v-rating>

                            <RepeaterStatusIcon :status="item.Status" />

                            <router-link :to="'/repeater/' + item.ID">{{ item.Name }}</router-link>
                        </template>

                        <template v-slot:item.Modified="{ item }">
                            <span :title="moment(item.Modified).format('MMM D, YYYY')">{{
                                item.Modified | fromNow
                            }}</span>
                            <v-icon class="ml-1" color="warning" title="Outdated Listing" small
                                v-if="moment().diff(moment(item.Modified), 'days') > 365">mdi-alert
                            </v-icon>
                        </template>

                        <template v-slot:item.Owner="{ item }">
                            <router-link :to="'/user/' + item.Owner">{{ item.Owner }}</router-link>
                        </template>

                        <template v-slot:item.Frequency="{ item }">
                            {{ item.Frequency }} MHz
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </div>

        <v-snackbar v-model="snackbar.state" :color="snackbar.color" :timeout="snackbar.timeout">
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="snackbar.state = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import axios from 'axios';
import config from '@/config';
import _ from 'lodash';
import moment from 'moment';
import RepeaterStatusIcon from '@/components/RepeaterStatusIcon.vue';
import { useTitle } from '@vueuse/core';

export default {
    name: 'FavoriteRepeaters',
    components: {
        RepeaterStatusIcon
    },
    props: ['user'],

    data: () => ({
        favorites: [],
        loading: false,
        noDataText: 'No favorite repeaters found',
        total: 0,
        options: {
            sortBy: ['Name'],
            sortDesc: [false],
            itemsPerPage: 0
        },
        snackbar: {
            state: false,
            color: null,
            timeout: 3000,
            text: null
        },
        cancelTokenSource: null,
        error: null
    }),

    watch: {
        "user.authenticated"() {
            if (this.user.authenticated) {
                this.fetchFavorites();
            }
        },

        options: {
            handler() {
                this.fetchFavorites();
            },
            deep: true
        }
    },

    computed: {
        headers() {
            let headers = [];

            if (this.$vuetify.breakpoint.xsOnly) {
                headers.push({ text: 'Name', value: 'Name', width: 200 });
            } else if (this.$vuetify.breakpoint.smOnly) {
                headers.push({ text: 'Name', value: 'Name', width: 200 });
                headers.push({ text: 'City', value: 'Location', width: 175 });
                headers.push({ text: 'State', value: 'State', width: 80 });
            } else if (this.$vuetify.breakpoint.mdOnly) {
                headers.push({ text: 'Name', value: 'Name', width: 200 });
                headers.push({ text: 'City', value: 'Location', width: 175 });
                headers.push({ text: 'State', value: 'State', width: 80 });
                headers.push({ text: 'Frequency', value: 'Frequency', width: 120 });
            } else if (this.$vuetify.breakpoint.lgOnly) {
                headers.push({ text: 'Name', value: 'Name', width: 200 });
                headers.push({ text: 'City', value: 'Location', width: 175 });
                headers.push({ text: 'State', value: 'State', width: 80 });
                headers.push({ text: 'Frequency', value: 'Frequency', width: 120 });
                headers.push({ text: 'Updated', value: 'Modified', width: 120 });
            } else if (this.$vuetify.breakpoint.xlOnly) {
                headers.push({ text: 'Name', value: 'Name', width: 200 });
                headers.push({ text: 'City', value: 'Location', width: 175 });
                headers.push({ text: 'State', value: 'State', width: 80 });
                headers.push({ text: 'Type', value: 'Type', width: 170 });
                headers.push({ text: 'Frequency', value: 'Frequency', width: 120 });
                headers.push({ text: 'Updated', value: 'Modified', width: 120 });
            }

            return headers;
        }
    },

    methods: {
        async fetchFavorites() {
            try {
                this.loading = true;
                this.error = null;

                let offset = ((this.options.page - 1) * this.options.itemsPerPage);
                const query = `${config.API_LOCATION}/favorite/repeaters?limit=${this.options.itemsPerPage}&skip=${offset}&sort=${this.options.sortBy}&descending=${this.options.sortDesc[0]}&offline=true&outdated=true&description=false`;

                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .get(query, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.total = (response.data && response.data.info) ? response.data.info.total : 0;
                        this.favorites = _.map(response.data.items, o => {
                            o.Frequency = o.Frequency.replace('46X', '462');
                            return o;
                        });
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        }
                    })
                    .then(() => {
                        this.loadingFavorites = false;
                    });

                this.loading = false;
            } catch (err) {
                this.loading = false;

                if (err.response && err.response.status === 401) {
                    this.$emit('unauthorized');
                } else {
                    console.error(err);
                    this.error = 'Error loading favorites';
                    this.showSnackbar('Error loading favorites', 'error');
                }
            }
        },

        setFavorite(repeaterId, starred) {
            let axiosHeaders = {};
            if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

            axios.request({
                url: config.API_LOCATION + '/favorite/repeater/' + repeaterId,
                headers: axiosHeaders,
                method: starred ? 'post' : 'delete'
            })
                .then(this.fetchFavoriteRepeaters)
                .catch(err => {
                    console.error(err);
                });
        },

        formatFrequency(freq) {
            if (!freq) return '';
            return parseFloat(freq).toFixed(4);
        },

        showSnackbar(text, color = 'info') {
            this.snackbar.text = text;
            this.snackbar.color = color;
            this.snackbar.state = true;
        },

        moment(date) {
            return moment(date);
        }
    },

    filters: {
        fromNow(date) {
            return moment(date).fromNow();
        }
    },

    mounted() {
        useTitle('Favorite Repeaters - myGMRS.com');
    },
}
</script>