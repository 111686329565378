<template>
  <v-card class="chat-container">
    <v-toolbar color="primary" dark dense class="mb-2 pr-2">
      <v-toolbar-title class="text-body-1">
        <v-icon class="mr-2" small>mdi-robot</v-icon>
        myGMRS Assistant
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')" small>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="chat-messages pa-2" ref="chatMessages">
      <div v-for="(message, index) in messages" :key="index" class="mb-2">
        <div class="d-flex" :class="message.role === 'user' ? 'justify-end' : 'justify-start'">
          <v-card 
            :color="message.role === 'user' ? 'rfaccent lighten-1' : ($vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3')" 
            :dark="message.role === 'user' || ($vuetify.theme.dark && message.role === 'assistant')"
            class="message-bubble pa-2"
            :class="message.role === 'user' ? 'user-message' : 'assistant-message'"
          >
            <div v-html="formatMessage(message.content)"></div>
          </v-card>
        </div>
      </div>
      <div v-if="isLoading" class="d-flex justify-start mb-2">
        <v-card :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'" 
                :dark="$vuetify.theme.dark"
                class="message-bubble pa-2 assistant-message">
          <v-progress-circular indeterminate size="20" width="2" color="primary"></v-progress-circular>
        </v-card>
      </div>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions class="pa-2">
      <v-text-field
        v-model="userInput"
        label="Ask a question..."
        outlined
        dense
        hide-details
        @keydown.enter="sendMessage"
        :disabled="isLoading"
        class="mr-2"
      ></v-text-field>
      <v-btn 
        color="primary" 
        @click="sendMessage" 
        :disabled="!userInput.trim() || isLoading"
        :loading="isLoading"
      >
        <v-icon>mdi-send</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>


<style scoped>
    .chat-container {
        display: flex;
        flex-direction: column;
        max-height: 80vh;
    }

    .chat-messages {
        height: 400px; /* Fixed height for the messages area */
        overflow-y: auto;
        background-color: transparent;
        padding-top: 0 !important;
    }

    .message-bubble {
        max-width: 80%;
        border-radius: 12px;
    }

    .user-message {
        border-bottom-right-radius: 4px;
    }

    .assistant-message {
        border-bottom-left-radius: 4px;
    }

    /* For markdown content */
    .message-bubble >>> p {
        margin-bottom: 0.5em;
    }

    .message-bubble >>> p:last-child {
        margin-bottom: 0;
    }

    .message-bubble >>> code {
        background-color: rgba(0, 0, 0, 0.1);
        padding: 2px 4px;
        border-radius: 4px;
        font-family: monospace;
    }

    .message-bubble >>> pre {
        background-color: rgba(0, 0, 0, 0.1);
        padding: 8px;
        border-radius: 4px;
        overflow-x: auto;
    }

    .message-bubble >>> a {
        color: inherit;
        text-decoration: underline;
    }
</style>

<script>
import axios from 'axios';
import config from '@/config';

export default {
  name: 'Assistant',
  
  props: ['authUser'],
  
  data() {
    return {
      userInput: '',
      messages: [
        {
          role: 'assistant',
          content: 'Hello! I\'m Bishop, the myGMRS Assistant. How can I help you with GMRS, radio, repeaters, or using the website today?'
        }
      ],
      isLoading: false,
      context: {}
    };
  },
  
  mounted() {
    this.scrollToBottom();
    this.determineContext();
  },
  
  updated() {
    this.scrollToBottom();
  },
  
  methods: {
    determineContext() {
      // Determine what page the user is on to provide context
      this.context = {
        currentRoute: this.$route.name,
        additionalInfo: ''
      };
      
      // Add specific context based on the current route
      switch(this.$route.name) {
        case 'home':
          this.context.additionalInfo = 'They are on the home page.';
          break;
        case 'repeaters':
          this.context.additionalInfo = 'They are viewing the repeater directory.';
          break;
        case 'map':
          this.context.additionalInfo = 'They are viewing the repeater map.';
          break;
        case 'repeater-detail':
          var repeaterId = this.$route.params.id;
          this.context.additionalInfo = `They are viewing details for repeater ID ${repeaterId}.`;
          break;
        case 'license':
          var callsign = this.$route.params.callsign;
          this.context.additionalInfo = `They are viewing license information for callsign ${callsign}.`;
          break;
        default:
          this.context.additionalInfo = `They are on the ${this.$route.name || 'unknown'} page.`;
      }
    },
    
    formatMessage(content) {
      // Simple markdown-like formatting without external libraries
      let html = this.escapeHtml(content);
      
      // Format code blocks
      html = html.replace(/```([^`]+)```/g, '<pre><code>$1</code></pre>');
      
      // Format inline code
      html = html.replace(/`([^`]+)`/g, '<code>$1</code>');
      
      // Format bold text
      html = html.replace(/\*\*([^*]+)\*\*/g, '<strong>$1</strong>');
      
      // Format italic text
      html = html.replace(/\*([^*]+)\*/g, '<em>$1</em>');
      
      // Format links
      html = html.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2" target="_blank">$1</a>');
      
      // Format paragraphs
      html = html.split('\n\n').map(p => `<p>${p}</p>`).join('');
      
      // Format line breaks
      html = html.replace(/\n/g, '<br>');
      
      return html;
    },
    
    escapeHtml(text) {
      const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
      };
      
      return text.replace(/[&<>"']/g, m => map[m]);
    },
    
    scrollToBottom() {
      if (this.$refs.chatMessages) {
        this.$nextTick(() => {
          this.$refs.chatMessages.scrollTop = this.$refs.chatMessages.scrollHeight;
        });
      }
    },
    
    async sendMessage() {
      if (!this.userInput.trim()) return;
      
      // Add user message
      this.messages.push({
        role: 'user',
        content: this.userInput
      });
      
      const userQuestion = this.userInput;
      this.userInput = '';
      this.isLoading = true;
      
      try {
        // Prepare headers with auth token if user is logged in
        let axiosHeaders = {};
        if (this.authUser && this.authUser.authToken) {
          axiosHeaders.Authorization = `Bearer ${this.authUser.authToken}`;
        }
        
        // Send request to backend
        const response = await axios.post(
          `${config.API_LOCATION}/assistant/chat`, 
          {
            message: userQuestion,
            context: this.context,
            history: this.messages.slice(0, -1) // Send previous messages as context
          },
          { headers: axiosHeaders }
        );
        
        // Add assistant response
        if (response.data && response.data.response) {
          this.messages.push({
            role: 'assistant',
            content: response.data.response
          });
        } else {
          throw new Error('Invalid response from server');
        }
      } catch (error) {
        console.error('Error sending message to assistant:', error);
        
        // Add error message
        this.messages.push({
          role: 'assistant',
          content: 'Sorry, I encountered an error processing your request. Please try again later.'
        });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
