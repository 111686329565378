<template>
    <span>
        <v-icon
            v-if="status === 'Online'"
            color="success"
            title="Online"
            small
            class="mr-2"
        >
            mdi-circle
        </v-icon>

        <v-icon
            v-else
            color="grey darken-1"
            title="Offline"
            small
            class="mr-2"
        >
            mdi-circle-off-outline
        </v-icon>
    </span>
</template>

<script>
    export default {
        name: 'RepeaterStatusIcon',

        props: ['status'],
    }
</script>
