<template>
    <v-container>
        <v-layout
                text-center
                wrap
        >
            <v-flex xs12>
                <v-img
                        :src="require('../assets/icon-512-transparent.png')"
                        class="my-5"
                        contain
                        :height="$vuetify.breakpoint.xsOnly ? 125 : 200"
                ></v-img>
            </v-flex>

            <v-flex mb-4>
                <h1 class="display-2 font-weight-thin mb-3">
                    Welcome to myGMRS.com!
                </h1>
                <p class="title font-weight-light">
                    myGMRS is a General Mobile Radio Service repeater directory and online community <br>where you can find answers to your two-way radio questions.
                </p>
            </v-flex>

        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: 'Welcome',

        data: () => ({}),
    };
</script>
