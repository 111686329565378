import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRating } from 'vuetify/lib/components/VRating';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[(!_vm.user.isAdFree)?_c('AdBTWR'):_vm._e(),_c('h1',{staticClass:"page-header font-weight-light"},[_vm._v("Favorite Repeaters")]),(_vm.user.authenticated)?_c('div',[_c(VAlert,{attrs:{"border":"left","color":"info","icon":"mdi-information","dense":"","text":""}},[_vm._v(" Keep track of your favorite repeaters here. You can also add repeaters to your favorites by clicking the star icon on the repeater page. ")]),_c(VCard,[_c(VCardText,[_c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.favorites,"loading":_vm.loading,"options":_vm.options,"footer-props":{ itemsPerPageOptions: [10, 20, 30, 40, 50] },"server-items-length":_vm.total,"mobile-breakpoint":0,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c(VRating,{attrs:{"clearable":"","dense":"","length":"1","color":"yellow darken-1","background-color":"almostblack lighten-5","title":"Favorite Repeater"},on:{"input":function($event){return _vm.setFavorite(item.ID, item.Starred)}},model:{value:(item.Starred),callback:function ($$v) {_vm.$set(item, "Starred", $$v)},expression:"item.Starred"}})]}},{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_c(VRating,{staticClass:"d-inline mr-2",attrs:{"clearable":"","dense":"","length":"1","color":"yellow darken-1","background-color":"almostblack lighten-5","title":"Favorite Repeater"},on:{"input":function($event){return _vm.setFavorite(item.ID, item.Starred)}},model:{value:(item.Starred),callback:function ($$v) {_vm.$set(item, "Starred", $$v)},expression:"item.Starred"}}),_c('RepeaterStatusIcon',{attrs:{"status":item.Status}}),_c('router-link',{attrs:{"to":'/repeater/' + item.ID}},[_vm._v(_vm._s(item.Name))])]}},{key:"item.Modified",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":_vm.moment(item.Modified).format('MMM D, YYYY')}},[_vm._v(_vm._s(_vm._f("fromNow")(item.Modified)))]),(_vm.moment().diff(_vm.moment(item.Modified), 'days') > 365)?_c(VIcon,{staticClass:"ml-1",attrs:{"color":"warning","title":"Outdated Listing","small":""}},[_vm._v("mdi-alert ")]):_vm._e()]}},{key:"item.Owner",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/user/' + item.Owner}},[_vm._v(_vm._s(item.Owner))])]}},{key:"item.Frequency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Frequency)+" MHz ")]}}],null,false,2696611208)})],1)],1)],1):_vm._e(),_c(VSnackbar,{attrs:{"color":_vm.snackbar.color,"timeout":_vm.snackbar.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar.state = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar.state),callback:function ($$v) {_vm.$set(_vm.snackbar, "state", $$v)},expression:"snackbar.state"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }